@import url("https://use.typekit.net/ikd4jet.css");

.App {
  height: 99vh;
}

h1, h2, h3, h4 {
  font-weight: 400;
}

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0;
  height: 80px;
}

.header-title-space {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.header-title {
  font-weight: 700;
  font-size: 22px;
  color: black;
  letter-spacing: -0.75px;
}

.site-page-header-ghost-wrapper {
  min-height: 80px;
  padding-left: 3vw;
  padding-right: 3vw;
}

.ant-tabs-tab-btn {
  font-weight: 700;
}

.ant-page-header {
  background-color: #ffffff;
  min-height: 80px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.ant-page-header-heading {
  width: 100%;
  padding: auto;
}

.ant-page-header-heading-extra {
  display: flex;
  align-items: center;
}

:root {
  --amplify-primary-color: #5051db;
  --amplify-primary-tint: #7374e2;
  --amplify-primary-shade: #4041af;
  --amplify-font-family: 'PostGrotesk-Medium';
}

.error-result {
  background-color: white;
  position: absolute;
  z-index: 1000;
  height: 99vh;
  width: 100%;
  top: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

[data-amplify-authenticator] {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: 25%;
}

.amplify-button[data-variation='primary'] {
  text-transform: uppercase;
  background: #5051DB;
}

.header-space {
  size: 100;
  display: flex;
  justify-content: space-between;
}

.report-page-space {
  width: 100%;
  padding: 0 20px 20px;
}

.html-frame {
    z-index: 1000;
    overflow: hidden;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    background-color: white;
}

.pdf-frame {
    z-index: 1000;
    overflow: hidden;
    position: absolute;
    width: 100% !important;
    height: 100% !important;
    top: 0;
    left: 0;
    background-color: white;
}

.modal-element {
    margin-bottom: 5px;
    width: 100%;
}

.modal-title {
    margin: 5px 0 1px 0;

}

div.ant-table-footer div {
  display: flex;
  justify-content: flex-end;
}

.notes-table th {
  border-bottom: 2px #e9e9e9 solid !important;
  border-right: 2px #e9e9e9 solid !important;
}

.tab-sider-space > div:first-child {
  height: 100%;
}

.ant-table-pagination.ant-pagination {
  margin: 16px !important;
}

.sign-in-form-window {
  height: 460px;
  width: 450px;
  padding: 35px 40px;
  border-radius: 6px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.15);
  position: absolute;
  top: calc(50% - 200px);
  left: calc(50% - 225px);
}