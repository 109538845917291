@import url("https://fonts.googleapis.com/css?family=Post%20Grotesk:400,300,300undefined,500|Karla:400,400italic,700,700italic&c=2587119010&");

body {
  margin: 0;
  font-family: PostGrotesk, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}